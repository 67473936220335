const state = () => ({
    scrollPosition: 0,
    csrfToken: '',
    siteUrl: '',
    isCookieOpen: false,
    isPolicyOpen: false,
})

// Getters
const getters = {
    scrollPosition: (state, getters) => {
        return state.scrollPosition
    }
}

// Actions
const actions = {
}

// Mutations
const mutations = {
    setCsrfToken(state, token) {
        state.csrfToken = token
    },
    setSiteUrl(state, value) {
        state.siteUrl = value
    },
    setCookieState(state, value){
      state.isCookieOpen = value;
    },
    setPolicyState(state, value){
      state.isPolicyOpen = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
