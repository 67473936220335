// Import
import { createApp, defineAsyncComponent } from "vue"

// Components
import store from './store'
import VueClickAway from "vue3-click-away";
import { sitedata } from '@/js/mixins/sitedata';
// import VueCookies from 'vue3-cookies';

// Declare
declare const window: any;

// App main
const main = async () => {
  const app = createApp({
    delimiters: ['${', '}'],
    components: {
      SiteMenu: defineAsyncComponent(() => import(/* webpackChunkName: "SiteMenu" */ '@/js/components/SiteMenu.vue')),
      PolicyPopup: defineAsyncComponent(() => import(/* webpackChunkName: "PolicyPopup" */ '@/js/components/PolicyPopup.vue')),
      CForm: defineAsyncComponent(() => import(/* webpackChunkName: "Form" */ '@/js/components/Form.vue')),
    },
    data: () => ({

		}),
    methods: {
      openCookie(){
        store.commit('app/setCookieState', true);
      },
      openPolicy(){
        store.commit('app/setPolicyState', true);
      }
    },
    beforeMount() {
			//Get sitedata
			store.commit('app/setSiteUrl', window.siteData.siteUrl)
			store.commit('app/setCsrfToken', window.siteData.csrf)
	   },
    });

	app.use(store);
	app.use(VueClickAway)
	// app.use(VueCookies);

	// Component liberaries

	//Mixin
	app.mixin(sitedata)

    // Mount the app
    const vm = app.mount('#app');
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
