import { mapState } from 'vuex'

export const sitedata = {

	data: () => ({

	}),

	computed: {
		...mapState({
			isCookieOpen: (state) => state.app.isCookieOpen,
			isPolicyOpen: (state) => state.app.isPolicyOpen,
      csrf: (state) => state.app.csrfToken
		})
	}
}
